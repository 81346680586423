import FullErrorPage from "../components-reusable/Errors/FullErrorPage/FullErrorPage";

export default function Custom404() {
  return (
    <FullErrorPage
      title="404 - Page not found"
      message="Page does not exist. Please check the URL in the address bar and try again."
    />
  );
}
